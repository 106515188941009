import React from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './container';
import { Link } from 'gatsby'
//import PostCardGroup from './PostCardGroup';
import { GatsbyImage } from "gatsby-plugin-image"

const RecentWrapper = styled.div`
  background: white;
  margin-bottom: 10rem;
  section {
    flex-direction: column;
    //flex-direction: row;
    .title, .posts {
      width: 900px;
      max-width: 100%;
      margin: auto;
      h2 {
        color: ${props => props.theme.colors.black};
        margin-bottom: 2rem;
        span{
          margin-left:1rem;
          vertical-align:top;
        }
      }
    }
    a{
      color:black;
      text-decoration: underline;
    }
`;

const PostWrapper = styled.div`
  background: white;
  display:flex;
  flex-direction: column;

  p{
    font-size:0.8em;
    margin-top: -1rem;
  }
  
`;

const SplitDivWrapper = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  .preview{
    width: 40%;
  }
  iframe{
    height: 250px;
    width: 50%;
  }
  img{
    height: 250px;
    //width: 450px;
  }

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    
      .preview{
        width: 100%;
        margin-bottom:1rem;
      }
      iframe{
        height: 300px;
        width: 100%;
        margin-bottom:1rem;
      }
      img{
        height: 250px;
        //width: 450px;
        margin-bottom:1rem;
      }
    }
`;

const KeywordWrapper = styled.span`
  //background: black;
  //color: white;
  margin-right: 1rem;
  border: 2px solid black;
  //text-align: center;
  padding: 3px;
  //margin-top: 2rem;
  
`;

const Divider = styled.hr`
  width:50%;
  text-align:left;
  margin-left:0;
  color:black;
  background-color:black;
  height:3px;
  border-width:0;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export default function Recent({ recent }) {

  //const answer_array = answer.split(',');
  function split(keywords) {
    return (
      keywords.split(',').map(keyword => <KeywordWrapper key={keyword}>{keyword.trim()}</KeywordWrapper>)
    );
  }

  function display_preview_media(meta) {
    
    if (!meta.video_src_url){
      return (
        <GatsbyImage image={meta.hero_image.childrenImageSharp[0].gatsbyImageData} alt={meta.hero_image_alt}/>
      )
    }
    
    return (
      <iframe
        src={meta.video_src_url}
        title={meta.video_title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    )
  }


  return (
    <RecentWrapper id="recent">
      <Container>
        <div className="title">
          <h2>Recent Posts<span><Link to={'/blog'}>All posts</Link></span></h2>
        </div>
        <div className="posts">
          {
            recent.map((node) => (
              <PostWrapper key={node.id}>
                <h3><Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link></h3>
                <p>{node.frontmatter.date}</p>
                <SplitDivWrapper>
                  <div className='preview'>{node.frontmatter.preview}</div>
                  {display_preview_media(node.frontmatter)}
                </SplitDivWrapper>
                <div>{split(node.frontmatter.keywords)}</div>

                <Divider />
              </PostWrapper>
            ))
          }
        </div>
      </Container>
    </RecentWrapper>
  )
}

// Recent.propTypes = {
//   posts: PropTypes.array.isRequired,
// };

