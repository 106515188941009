import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './container';
//import fetch from 'isomorphic-unfetch';
//import endpoints from '../util/endpoints';
//import LoadingIcon from '../images/loading.svg';
//import ReactAudioPlayer from 'react-audio-player';
import { MDXRenderer } from 'gatsby-plugin-mdx'

const AboutWrapper = styled.div`
  //margin: 10rem 0;
  //height: 300px;
  //margin-bottom: 10rem;
  @media screen and (max-width: ${props => props.theme.sizes.mobile}) {
    height: unset;
    padding-top: 10rem;
  }
  section {
    flex-direction: column;
    width: 1020px;
    max-width: 100%;
    h2 {
      color: black;
      margin-bottom: 2rem;
    }
    small {
      font-size: 1rem;
      line-height: 1rem;
    }
    .body {
      a {
        position: relative;
        z-index: 5;
        text-decoration: none;
        color: white;
        transition: all 0.3s ease;
        padding: 0 0.5rem;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
    }
  }
`;

export default class HomeAbout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      track: {},
      error: false,
      fetching: true,
    };
  }
  static propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  };

  render() {
    let { title, body } = this.props;
    //let { error } = this.state;
    return (
      <AboutWrapper id="about">
        <Container>
          <div className="title">
            <h2>{title}</h2>
          </div>
          <MDXRenderer>{body}</MDXRenderer>
          {/* {!error && (
            <div className="listening">
              <h3>
                Now Playing (Probably){' '}
                <button onClick={this.getTrack}>Refresh</button>
              </h3>
              {this.renderPlayer()}
            </div>
          )} */}
        </Container>
      </AboutWrapper>
    );
  }
}