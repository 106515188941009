import * as React from 'react'
//import PropTypes from 'prop-types';
import styled from 'styled-components';
//import { Link, useStaticQuery, graphql } from 'gatsby'
import Container from './container'
import { GatsbyImage } from "gatsby-plugin-image"

const InterestsWrapper = styled.div`
  margin: 10rem 0;
  @media (max-width: ${props => props.theme.sizes.mobile}) {
    margin-bottom: 7rem;
  }
  //height: 700px;
  section {
    flex-direction: column;
    width: 1020px;
    max-width: 100%;
    h2 {
      color: ${props => props.theme.colors.black};
      margin-bottom: 2rem;
    }
  
    small {
      font-size: 1rem;
      line-height: 1rem;
    }
    .body {
      a {
        position: relative;
        z-index: 5;
        text-decoration: none;
        color: white;
        transition: all 0.3s ease;
        padding: 0 0.5rem;
      }
    }
    }
  }
`;

const PostCardWrapper = styled.div`
  background: white;
  color = black;
  font-size: 3.5rem;

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    margin-bottom:3rem;
  }

`;

const PostCardGroupWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  //flex-wrap: nowrap;
  position: relative;

  @media (max-width: ${props => props.theme.sizes.mobile}) {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
  

`;

const ImgGridWrapper = styled.div`
  display: flex;
  //justify-content: space-between; 
  
  img{
    height:  25px;
    width: auto;
    padding-right: 1rem;
  }
`;

const Interests = ({ logos }) => {

  return (
    <InterestsWrapper id="interests">
      <Container>
        <div className="title">
          <h2>Interests</h2>
        </div>
        <PostCardGroupWrapper>
        <PostCardWrapper>Performance Testing
            <ImgGridWrapper>
              {logos.filter(function (obj) {
                return obj.relativeDirectory==='logos/performance';
              }).map(node =>
                <GatsbyImage image={node.childrenImageSharp[0].gatsbyImageData} alt={node.name} key={node.name}/>
              )}
            </ImgGridWrapper>
          </PostCardWrapper>

          <PostCardWrapper>Cloud
            <ImgGridWrapper>
              {logos.filter(function (obj) {
                return obj.relativeDirectory==='logos/cloud';
              }).map(node =>
                <GatsbyImage image={node.childrenImageSharp[0].gatsbyImageData} alt={node.name} key={node.name}/>
              )}
            </ImgGridWrapper>
          </PostCardWrapper>

          <PostCardWrapper>
            Machine Learning
            {/* <StaticImage src="../images/pandas.png" alt="pandas" /> */}
            {/* <GatsbyImage image={logos} alt="{data.blogPost.author}" /> */}
            <ImgGridWrapper>
              {logos.filter(function (obj) {
                return obj.relativeDirectory==='logos/ml';
              }).map(node =>
                <GatsbyImage image={node.childrenImageSharp[0].gatsbyImageData} alt={node.name} key={node.name}/>
              )}
            </ImgGridWrapper>
          </PostCardWrapper>
          
          </PostCardGroupWrapper>

      </Container>
    </InterestsWrapper>
  )
}

export default Interests

//Interests.propTypes = {
//  interests: PropTypes.array.isRequired,
//};

