import * as React from 'react'
import { graphql } from "gatsby"
import SEO from '../components/seo';
import Hero from '../components/hero';
import About from '../components/about';
import Interests from '../components/interests'
import Recent from '../components/recent';
import Contact from '../components/contact'
// import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO />
      <Hero />
      <About title="About" body={data.mdx.body} />
      <Interests pageTitle="Interesting" id="interests" logos={data.allFile.nodes} />
      <Recent posts="posts preview" recent={data.allMdx.nodes} />
      <Contact />
    </React.Fragment>

  )
}

export const query = graphql`
  {
    allFile(filter: {relativePath: {regex: "/logos/"}}) {
      nodes {
        relativeDirectory
        name
        childrenImageSharp {
          gatsbyImageData(placeholder:BLURRED)
        }
      }
    }
    allMdx(sort: {fields: frontmatter___date, order: DESC}
      filter: {frontmatter: {date: {ne: null}}}
      limit: 3
      ) {
          nodes {
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                keywords
                preview
                video_src_url
                video_title
                hero_image_alt
                hero_image {
                  childrenImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                    )
                  }
                }
              }
              id
              slug
              
          }
      }
      mdx(frontmatter: {title: {eq: "About"}}) {
        body
      }
  }
`

export default IndexPage