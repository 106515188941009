import React from 'react';
//import PropTypes from 'prop-types';
//import Img from 'gatsby-image';
import styled from 'styled-components';
import Container from './container';
//import Button from './Button';
import { StaticImage } from "gatsby-plugin-image"
//import { Link } from 'gatsby';

const HeroWrapper = styled.div`
  //margin-top: -80px;
  position: relative !important;
  height: 500px;
  //background: ${props => props.theme.colors.red};
  @media screen and (max-width: ${props => props.theme.sizes.mobile}) {
    height: unset;
    padding-top: 50px;
  }
  section {
    align-items: center;
    position: relative;
    z-index: 100;
    .content {
      position: relative;
      z-index: 100;
      width: 60%;
      margin-left: 2rem;
      @media screen and (max-width: ${props => props.theme.sizes.tablet}) {
        width: 100%;
        margin-left: 0;
        button {
          width: 100%;
        }
      }
      h1,
      h2,
      p {
        margin: 0;
      }
      h1,
      p {
        line-height: 2.2rem;
        font-weight: 700;
      }
      h2 {
        font-size: 6rem;
        line-height: 6rem;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        @media screen and (max-width: ${props => props.theme.sizes.mobile}) {
          font-size: unset;
          font-size: 3.5rem;
          line-height: 3rem;
        }
      }
      h1,
      p {
        font-size: 1.6rem;
        font-family: 'Ubuntu', sans-serif;
        text-transform: none !important;
        font-weight: 400 !important;
      }
    }
  }
`;

const ImgGridWrapper = styled.div`
  display: flex;
  //justify-content: space-between; 
  
  img{
    height:  25px;
    width: auto;
    padding-right: 1rem;
  }
`;

const Hero = ({}) => (
  
  <HeroWrapper id="home">
    <Container>
      <div className="content">
        <p>Welcome to my page, my name is</p>
        <h2>Salim</h2>
        <h2>BEN HADJ HASSIN</h2>
        <ImgGridWrapper>
        {/* <a target="_blank" rel="noopener noreferrer" href="https://github.com/the-engineer-io"><StaticImage src="../images/social_media/github.png" alt="Github" placeholder="blurred"/></a> */}
        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/salimbenhadjhassin/"><StaticImage src="../images/social_media/linkedin.png" alt="LinkedIn" placeholder="blurred"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.kaggle.com/xclimx"><StaticImage src="../images/social_media/kaggle.png" alt="Kaggle" placeholder="blurred"/></a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.hackerrank.com/salimb2h"><StaticImage src="../images/social_media/hackerrank.png" alt="HackerRank" placeholder="blurred"/></a>
        {/* <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/"><StaticImage src="../images/social_media/youtube.png" alt="Youtube" placeholder="blurred"/></a> */}
        </ImgGridWrapper>
      </div>
      
    </Container>
  </HeroWrapper>
);


export default Hero;
