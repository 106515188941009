import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from './container';
//import ContactForm from './ContactForm'
//import PostCardGroup from './PostCardGroup';
import fetch from 'isomorphic-unfetch';
import ReCAPTCHA from 'react-google-recaptcha';
import LoadingIcon from '../images/loading.svg';

const ContactWrapper = styled.div`
  background: white;
  margin-bottom: 10rem;
  section {
    flex-direction: column;
    .title, .form {
      width: 900px;
      max-width: 100%;
      margin: auto;
      h2 {
        color: black;
        margin-bottom: 2rem;
      }
    }
`;

const Confirm = styled(ReCAPTCHA)`
  margin-bottom: 2rem;
`;

const Form = styled.form`
  opacity: ${props => (props.loading === 'true' ? `.5` : `1`)};
  transition: opacity 0.3s ease;
  //margin: auto;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  //margin-top: 3rem;
  

  @media screen and (max-width: 600px) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }

  input,
  textarea {
    font-family: 'Ubuntu', sans-serif;
    background: white;
    font-size: inherit;
    border: none;
    outline: none;
    padding: 1em;
    &::-webkit-input-placeholder {
      color: ${props => props.theme.colors.black};
      text-transform: uppercase;
    }
    &::-moz-placeholder {
      color: ${props => props.theme.colors.black};
      text-transform: uppercase;
    }
    &:-ms-input-placeholder {
      color: ${props => props.theme.colors.black};
      text-transform: uppercase;
    }
    &:-moz-placeholder {
      color: ${props => props.theme.colors.black};
      text-transform: uppercase;
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      outline: none;
    }
  }
`;

const Input = styled.input.attrs(() => ({
  'data-hj-whitelist': '',
}))`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  opacity:60%
`;

const InputWrapper = styled.div`
  //padding: 0.2rem;
  border: 0.2rem solid ${props => props.theme.colors.black};
  margin-bottom: 2rem;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

const Message = styled.textarea.attrs(() => ({
  'data-hj-whitelist': '',
}))`
  width: 100%;
  line-height: 1.6;
  resize: vertical;
  min-height: 150px;
  opacity:60%
`;

const SubmitButton = styled.button`
  border: 0.2rem solid black !important;
  color: black;
  font-family: 'Ubuntu', sans-serif;
  text-transform: uppercase;
  background: white;
  height: 4.5rem;
  width: 120px;
  max-width: 100%;
  border-radius: 0;
  opacity: ${props => (props.buttonDisabled ? 0.5 : 1)} !important;
  pointer-events: ${props => props.buttonDisabled ? 'none' : 'all'} !important;
`;

const Loading = styled.span`
  line-height: 4rem;
  margin-left: 1rem;
`;

const SplitDivWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Modal = styled.div` 
  visibility: ${props => (props.visible === 'true' ? 'visible' : 'hidden')};
  //visibility: visible;
  
  @media screen and (min-width: ${props => props.theme.sizes.mobile}) {
    min-width: inherit;
    max-width: 400px;
  }
`;

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullname: '',
      email: '',
      subject: '',
      message: '',
      showModal: false,
      valid: false,
      error: false,
      loading: false,
      captcha: '',
    };
  }

  // static propTypes = {
  //   hubspotForm: PropTypes.string.isRequired,
  //   hubspotPortal: PropTypes.string.isRequired,
  // };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleError = () => {
    this.setState({
      showModal: true,
      valid: false,
      loading: false,
      error: true,
    });
  };

  handleSubmit = e => {
    if (e) e.preventDefault();
    const {
      fullname,
      email,
      subject,
      message,
      valid,
      loading,
      captcha,
    } = this.state;
    if (!valid || loading) return null;

    this.setState({ loading: true });
    const data = { fullname, email, subject, message, captcha };
    // fetch('/.netlify/functions/contact', {
    //   method: 'POST',
    //   body: JSON.stringify(data),
    //   headers: new Headers({
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    //   }),
    // })
    //   .then(() => this.submitForm(data))
    //   .catch(() => this.handleError());

    // Attempt to solve  
    try {
      this.submitForm(data)
    } catch (err) {
      this.handleError()
    }
  };

  submitForm = data => {
    //const isBrowser = typeof window !== 'undefined';
    //const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    //const pageUri = isBrowser ? window.location.href : null;
    //const pageName = isBrowser ? document.title : null;
    //const postUrl = `https://contact-me-form.free.beeceptor.com`;
    //const postUrl = `http://127.0.0.1:105/contact-form`;
    const postUrl = `https://33s0xwaosl.execute-api.us-east-1.amazonaws.com/dev/contact-form`;
    //const postUrl = `api_gateway_url`;

    const body = {
      submittedAt: Date.now(),
      fullname: data.fullname,
      email: data.email,
      message: data.message,
      subject: data.subject,
      captcha: data.captcha,
    };

    fetch(postUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response['statusCode']===200){
          return response;
        }else{
          throw new Error(response['body'])
        }
      })
      .then(() => {
        this.setState({
          fullname: '',
          email: '',
          subject: '',
          message: '',
          showModal: true,
          valid: false,
          loading: false,
          error: false,
          captcha: '',
        });
      })
      .catch(err => {
        //console.log(JSON.stringify(body))
        //console.log(response)
        console.warn(err);
        this.handleError();
      });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  onConfirm = (value) => {
    this.setState({ valid: true, captcha: value });
  };

  render() {
    let {
      fullname,
      email,
      subject,
      message,
      loading,
      error,
      valid,
    } = this.state;
    return (
      <ContactWrapper id="contact">
        <Container>
          <div className="title">
            <h2>Contact</h2>
          </div>

          <div className="form">
            <Form
              //data-form-id={this.props.hubspotForm}
              //data-portal-id={this.props.hubspotPortal}
              //name="Contact Chase Ohlson"
              onSubmit={this.handleSubmit}
              //overlay={this.state.showModal}
              onClick={this.closeModal}
              loading={this.state.loading.toString()}
            >

              <InputWrapper>
                <Input
                  name="fullname"
                  type="text"
                  placeholder="Full Name *"
                  value={fullname}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>

              <InputWrapper>
                <Input
                  name="email"
                  type="email"
                  placeholder="Email *"
                  value={email}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>

              <InputWrapper>
                <Input
                  name="subject"
                  type="subject"
                  placeholder="Subject *"
                  value={subject}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>
              <InputWrapper>
                <Message
                  name="message"
                  type="text"
                  placeholder="Message *"
                  value={message}
                  onChange={this.handleInputChange}
                  required
                />
              </InputWrapper>
              {!loading && (
                <Confirm
                  sitekey="6LddSZUeAAAAAIMGqe_rU60ZkYMOZiJo9HyHlwrQ" //dev
                  theme="dark"
                  onChange={this.onConfirm}
                />
              )}
              
              
              <SplitDivWrapper>
                <SubmitButton buttonDisabled={!valid} name="submit" type="submit">Send</SubmitButton>
                {loading && (
                <Loading>
                  <img alt="loading" src={LoadingIcon} />
                </Loading>
              )} 
              </SplitDivWrapper>
              
              
              <Modal visible={this.state.showModal.toString()}>
                <p>
                  {error
                    ? `Something isn't right. Please check your email address and try again. `
                    : `Message sent. I'll get back to you as soon as possible.`}
                </p>
              </Modal>
            </Form>
          </div>
        </Container>


      </ContactWrapper>
    );
  }
}
