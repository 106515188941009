import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
    title,
    author,
    description,
    article,
    image,
    keywords,
    createdAt,
    updatedAt,
}) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)

    const {
        siteUrl,
        defaultTitle,
        titleTemplate,
        defaultAuthor,
        defaultDescription,
        defaultImage,
        twitterUsername,
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        author: author || defaultAuthor,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    }

    return (
        <Helmet title={seo.title}> titleTemplate={titleTemplate}
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />

            {seo.url && <meta property="og:url" content={seo.url} />}

            {(article ? true : null) && <meta property="og:type" content="article" />}

            {seo.title && <meta property="og:title" content={seo.title} />}

            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}

            {(article ? true : null) && seo.author && (
                <meta property="article:author" content={seo.author} />
            )}

            {keywords && (
                <meta property="keywords" content={keywords} />
            )}

            {createdAt && (
                <meta property="og:published_time" content={createdAt} />
            )}

            {updatedAt && (
                <meta property="og:updated_time" content={updatedAt} />
            )}

            {seo.image && <meta property="og:image" content={seo.image} />}

            <meta name="twitter:card" content="summary_large_image" />

            {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
            )}

            {seo.title && <meta name="twitter:title" content={seo.title} />}

            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}

            {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
    )
}

export default SEO

SEO.propTypes = {
    title: PropTypes.string,
    author: PropTypes.string,
    description: PropTypes.string,
    article: PropTypes.bool,
    image: PropTypes.string,
    keywords: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
}

SEO.defaultProps = {
    title: null,
    author: null,
    description: null,
    article: false,
    image: null,
    keywords: null,
}

const query = graphql`
  query SEO {
    site {
        siteMetadata {
          siteUrl
          defaultTitle: title
          titleTemplate
          defaultAuthor: author
          defaultDescription: description
          defaultImage: image
          twitterUsername
        }
      }
  }
`